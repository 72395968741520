import React from "react";
import { Box, Typography, Button } from "@mui/material";
import {Link} from 'react-router-dom';
import { checkLogin } from "../../../Redux/RequestHandlers/Auth.action";
import { DescriptionComponent } from "../../../components/Helpers/helperComponents";
export default function CSevenMissionCardComponent() {
  const isLogin = checkLogin();
  return (
    <Box
      id="AboutUsMissionCard"
      sx={{
        p: "64px 0px 88px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "16px",
        borderRadius: "24px",
        background: "#000",
        width: "100%",
      }}
    >
      <Typography
        sx={{
          color: " #fff",
          textAlign: "center",
          fontFeatureSettings: " 'ss06' on",
          fontFamily: "SF Pro Display",
          fontSize: "40px",
          fontStyle: "normal",
          fontWeight: "500",
          lineHeight: "56px",
          letterSpacing: "-0.4px",
          maxWidth:"500px",
          "@media (max-width:800px)": {
            px: "30px",
            fontSize: "2.075rem",
            lineHeight: "50px",
          },
        }}
      >
       Start your all-inclusive way 
to receive payments or shop

      </Typography>
      <DescriptionComponent 
      text={<>
      Join our mission to bring greater choice, independence, and opportunity
        to<br /> individuals around the world.</>}
        ta="center"
        fs="16px"
        color="#C8C8D0"
        />
     
      {isLogin ? <Button
       component={Link}
       to="/dashboard/"
        sx={{
          color: "#fff",
          background: "#495ECA",
          borderRadius: "8px",
          display: "block",
          textTransform: "none",
          fontSize: "16px",
          fontWeight: 500,
          lineHeight: "25.736px",
          padding: "11px 24px",
          letterSpacing: "0.08px",
          fontFamily: "SF Pro Display",
          fontStyle: "normal",
          "&:hover": {
            background: "#495ECA",
            color: "#fff",
          },
          "@media (max-width:800px)": { px: "30px" },
        }}
      >
        Go to Dashboard
      </Button>:<Button
       component={Link}
       to="/signup/"
        sx={{
          color: "#fff",
          background: "#495ECA",
          borderRadius: "8px",
          display: "block",
          textTransform: "none",
          fontSize: "16px",
          fontWeight: 500,
          lineHeight: "25.736px",
          padding: "11px 24px",
          letterSpacing: "0.08px",
          fontFamily: "SF Pro Display",
          fontStyle: "normal",
          "&:hover": {
            background: "#495ECA",
            color: "#fff",
          },
          "@media (max-width:800px)": { px: "30px" },
        }}
      >
        Sign Up Now
      </Button>}
    </Box>
  );
}
