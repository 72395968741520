import React from "react";
import { Box, Grid } from "@mui/material";
import Img1 from "../../../assets/img/About Us/3.png";
import Img2 from "../../../assets/img/About Us/2.png";
export default function ComponentThree() {
  return (
    <Box
      sx={{
        p: "80px 160px",
        "@media (max-width:800px)": { p: "0px 30px 40px!important" },
      }}
    >
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          md={6}
          lg={6}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Box
            component="img"
            src={Img1}
            sx={{
              width: "100%",
              maxWidth: "782.6px",
              maxHeight: "339px",
              boxShadow: "0px 4px 40px 0px rgba(0, 0, 0, 0.10)",
              "@media (max-width:800px)": { mb: "40px" },
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          lg={6}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Box
            component="img"
            src={Img2}
            sx={{
              width: "100%",
              maxWidth: "782.6px",
              maxHeight: "339px",
              boxShadow: "0px 4px 40px 0px rgba(0, 0, 0, 0.10)",
              "@media (max-width:800px)": { mb: "40px" },
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
