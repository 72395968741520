import "./assets/css/style.css";
import AccessGuard, { AccessGuardWithLayout } from "./components/Guard";
import LoaderComponent, { LoaderComponentMin } from "./components/Guard/LoaderComponent";
// import { accessKeys } from "./components/Guard/accessKeys";
import { GafahPay, AboutComponent, ContactUsComponent, PricingComponent, SupportComponent, FAQComponent, LoginComponent, SignUpComponent, Dashboard, Wallet, InitiateForgetPassword, VerifyForgetPassword, UpdateProfile, OtpVerification, ResetPassword, APIKeyLists, ChangePassword, Profile, Notification, Transaction, Prices, Security, PrivacyNotifications, PaymentMethod, Withdrawal, IdentityVerificationComponent, CompanyVerification, BankVerification, POSSelect, POSSuccess, GafahWallet, ExternalWallet, SupportDetails, RefundComponent } from "./containers";
import React from "react";
import { ToastContainer } from 'react-toastify'
import { BrowserRouter, Routes, Route } from "react-router-dom";
function App() {
  return (
    <BrowserRouter>
      <ToastContainer />
      <Routes>
        <Route exact path="/" element={<LoaderComponentMin component={<GafahPay />} />} />
        <Route path="/aboutus" element={<AboutComponent />} />
        <Route path="/contactus" element={<ContactUsComponent />} />
        {/* <Route path="/pricing" element={<LoaderComponent component={<PricingComponent />} />} />
        <Route path="/support" element={<LoaderComponent component={<SupportComponent />} />} />
        <Route path="/support/:type" element={<LoaderComponent component={<SupportDetails />} />} />
        <Route path="/faq" element={<LoaderComponent component={<FAQComponent />} />} />
        <Route path="/signup" element={<LoaderComponent component={<SignUpComponent />} />} />
        <Route path="/account/setup" element={<AccessGuard accKey={accessKeys?.accountSetup} component={<UpdateProfile />} />} />
        <Route path="/account/verify" element={<AccessGuard accKey={accessKeys?.verifyRegisterEmail} component={<OtpVerification />} />} />
        <Route path="/login" element={<LoaderComponent component={<LoginComponent />} />} />
        <Route path="/notification" element={<Notification />} /> */}

        {/* <Route path="/dashboard" element={<AccessGuard accKey={accessKeys?.marchant} component={<Dashboard />} />} />
        <Route path="/api-keys" element={<AccessGuardWithLayout accKey={accessKeys?.marchant} component={<APIKeyLists />} />} />
        <Route path="/wallet" element={<AccessGuard accKey={accessKeys?.marchant} component={<Wallet />} />} />
        <Route path="/transactions" element={<AccessGuard accKey={accessKeys?.marchant} component={<Transaction />} />} />
        <Route path="/prices" element={<AccessGuardWithLayout accKey={accessKeys?.marchant} component={<Prices />} />} />
        <Route path="/withdrawal" element={<AccessGuard accKey={accessKeys?.marchant} component={<Withdrawal />} />} /> */}

        {/* settings */}
        {/* <Route path="/settings/profile" element={<AccessGuardWithLayout accKey={accessKeys?.marchant} component={<Profile />} />} />
        <Route path="/settings/change-password" element={<AccessGuardWithLayout accKey={accessKeys?.marchant} component={<ChangePassword />} />} />
        <Route path="/settings/security" element={<AccessGuardWithLayout accKey={accessKeys?.marchant} component={<Security />} />} />
        <Route path="/settings/privacy-notifications" element={<AccessGuardWithLayout accKey={accessKeys?.marchant} component={<PrivacyNotifications />} />} />
        <Route path="/settings/payment-methods" element={<AccessGuardWithLayout accKey={accessKeys?.marchant} component={<PaymentMethod />} />} />
        <Route path="/settings/kyc/identity-verification" element={<AccessGuardWithLayout accKey={accessKeys?.marchant} component={<IdentityVerificationComponent />} />} />
        <Route path="/settings/kyc/company-verification" element={<AccessGuardWithLayout accKey={accessKeys?.marchant} component={<CompanyVerification />} />} />
        <Route path="/settings/kyc/bank-verification" element={<AccessGuardWithLayout accKey={accessKeys?.marchant} component={<BankVerification />} />} /> */}

        {/* POS */}
        {/* <Route path="/invoice" element={<POSSelect />} />
        <Route path="/invoice/gafah" element={<GafahWallet />} />
        <Route path="/invoice/external" element={<ExternalWallet />} />
        <Route path="/invoice/success/:id" element={<POSSuccess />} />
        <Route path="/invoice/refund/:id" element={<RefundComponent />} /> */}

        {/* reset password */}
        {/* <Route path="/reset-password/initiate" element={<InitiateForgetPassword />} />
        <Route path="/reset-password/verify-email" element={<AccessGuard accKey={accessKeys?.verifyForgetPassEmail} component={<VerifyForgetPassword />} />} />
        <Route path="/reset-password/set-password" element={<AccessGuard accKey={accessKeys?.resetPassword} component={<ResetPassword />} />} /> */}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
