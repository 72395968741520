import React from "react";
import { Box, Typography, Grid } from "@mui/material";
import CSixGridComponent from "./CSixGrid";
import posMachine from "../../../assets/img/home/ComponentSix/posMachine.svg";
import Img2 from "../../../assets/img/home/ComponentSix/2.png";
import Img3 from "../../../assets/img/home/ComponentSix/3.png";
import Img4 from "../../../assets/img/home/ComponentSix/4.png";

export default function ComponentSix() {
  return (
    <Box
      id="endUserInteraction"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "70px 120px 96px",
        "@media (max-width:800px)": { px: "40px" },
      }}
    >
      <Typography
        sx={{
          color: "#495ECA",
          textAlign: "center",
          fontFeatureSettings: " 'ss06' on",
          fontFamily: "SF Pro Display",
          fontSize: "14px",
          fontStyle: "normal",
          fontWeight: "500",
          lineHeight: "19.6px",
          textTransform: "uppercase",
        }}
      >
        Partners
      </Typography>
      <Typography
        sx={{
          textAlign: "center",
          fontFeatureSettings: " 'ss06' on",
          fontFamily: "Poppins",
          fontSize: "40px",
          fontStyle: "normal",
          fontWeight: "600",
          lineHeight: "56px",
          letterSpacing: "-0.4px",
          mt: "16px",
        }}
      >
        See What We Do
      </Typography>
      <Box
        sx={{
          mt: "17px",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          px: "30px!important",
        }}
      >
        <CSixGridComponent
          img={posMachine}
          head="Enabling secure POS Crypto Payment"
          content="Retail inclusion by payment processing through POS machines"
          rightAlignment={true}
        />
        <CSixGridComponent
          img={Img2}
          head="Providing the fastest, lowest-cost transactions"
          content="Process payments by directly integrating our payment platform in online applications"
          rightAlignment={false}
        />
        <CSixGridComponent
          img={Img3}
          head="One Seamless Merchant Portal"
          content="Comprehensive merchant portal for transaction analysis, fund withdrawal and other processes"
          rightAlignment={true}
        />
        <CSixGridComponent
          img={Img4}
          head="Daily Crypto-use via Gafah Crypto Wallet"
          content="Seemless user application for payments using multiple cryptocurrencies"
          rightAlignment={false}
        />
      </Box>
    </Box>
  );
}
