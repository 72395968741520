import React from "react";
import { Box, Typography, Button } from "@mui/material";
export default function COneLastSectionHeading(props) {
  const { title, subHeading } = props;
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      {title === "5+" ? (
        <Box sx={{ display: "flex", alignItems:"center" }}>
          {" "}
          <Typography
            sx={{
              color: " var(--main-colors-black, #0E0637)",
              textAlign: "center",
              fontFeatureSettings: " 'ss06' on",
              fontFamily: "SF Pro Display",
              fontSize: "32.4px",
              fontStyle: "normal",
              fontWeight: "500",
              lineHeight: "45.36px",
              letterSpacing: " -0.324px",
            }}
          >
            {title}
          </Typography>
          <Box
            sx={{
              p: "3.24px 9.72px!important",
              borderRadius: "19.44px",
              background: "#495ECA",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              maxHeight: "20px",
            }}
          >
            {" "}
            <Typography
              sx={{
                color: " var(--main-colors-black, #0E0637)",
                textAlign: "center",
                fontFeatureSettings: " 'ss06' on",
                fontFamily: "SF Pro Display",
                fontSize: "9.72px",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "13.122px",
                letterSpacing: " 0.049px",
              }}
            >
              counting...
            </Typography>
          </Box>
        </Box>
      ) : (
        <Typography
          sx={{
            color: " var(--main-colors-black, #0E0637)",
            textAlign: "center",
            fontFeatureSettings: " 'ss06' on",
            fontFamily: "SF Pro Display",
            fontSize: "32.4px",
            fontStyle: "normal",
            fontWeight: "500",
            lineHeight: "45.36px",
            letterSpacing: " -0.324px",
          }}
        >
          {title}
        </Typography>
      )}

      <Typography
        sx={{
          color: " var(--neutral-500, #717184)",
          textAlign: "center",
          fontFeatureSettings: " 'ss06' on",
          fontFamily: "SF Pro Display",
          fontSize: "11.34px",
          fontStyle: "normal",
          fontWeight: "400",
          lineHeight: "16.443px",
        }}
      >
        {subHeading}
      </Typography>
    </Box>
  );
}
