import React from "react";
import { Box, Typography } from "@mui/material";
import Img1 from "../../../assets/img/Contact Us/1.png";
import Img2 from "../../../assets/img/Contact Us/2.png";
import { Link } from "react-router-dom";
export default function ComponentOne() {
  return (
    <Box
      sx={{
        mx: "120px",
        position: "relative",
        mt: "-5rem",
        display: "flex",
        justifyContent: "center",
        gap: "27px",
        "@media (max-width:1300px)": {
          flexDirection: "column",
          alignItems: "center",
        },
        "@media (max-width:700px)": { mx: "50px!important", mt: "-2.5rem" },
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          p: "34px 38px",
          width: "583px",
          borderRadius: "40px",
          border: "1px solid #E8E8E8",
          background: "#FFF",
          boxShadow: "0px 10px 33px 0px rgba(181, 181, 181, 0.25)",
          "@media (max-width:700px)": {
            width: "auto",
            p: "14px 15px",
            borderRadius: "20px",
            gap: "11px",
          },
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box
            component="img"
            src={Img1}
            sx={{
              height: "83px",
              width: "83px",
              "@media (max-width:700px)": { width: "40px", height: "auto" },
            }}
          />
          <Typography
            sx={{
              ml: "16px",
              color: "#1B2534",
              fontFamily: "SF Pro Display",
              fontSize: " 24px",
              fontStyle: "normal",
              fontWeight: 600,
              lineHeight: "77.16px",
              textTransform: "capitalize",
              "@media (max-width:700px)": {
                fontSize: "16px",
                lineHeight: "20px",
                ml: "5px",
              },
            }}
          >
            Mail Us
          </Typography>
        </Box>
        <Typography
          component={Link}
          to="mailto:info@gafah.com"
          sx={{
            color: "#797979",
            textDecoration: "none",
            fontFamily: "SF Pro Display",
            fontSize: " 22px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "34.628px",
            letterSpacing: "1.32px",
            textTransform: "lowercase",
            "@media (max-width:700px)": {
              fontSize: "16px",
              lineHeight: "20px",
            },
          }}
        >
          info@gafah.com
        </Typography>
      </Box>
      {/* <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          p: "34px 38px",
          width: "583px",
          borderRadius: "40px",
          border: "1px solid #E8E8E8",
          background: "#FFF",
          boxShadow: "0px 10px 33px 0px rgba(181, 181, 181, 0.25)",
          "@media (max-width:700px)": {
            width: "auto",
            p: "14px 15px",
            borderRadius: "20px",
            gap: "11px",
            width: "100%",
          },
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box
            component="img"
            src={Img2}
            sx={{
              height: "83px",
              width: "83px",
              "@media (max-width:700px)": { width: "40px", height: "auto" },
            }}
          />

          <Typography
            sx={{
              ml: "16px",
              color: "#1B2534",

              fontFamily: "SF Pro Display",
              fontSize: " 24px",
              fontStyle: "normal",
              fontWeight: 600,
              lineHeight: "77.16px",
              textTransform: "capitalize",
              "@media (max-width:700px)": {
                fontSize: "14px",
                lineHeight: "20px",
                ml: "5px",
              },
            }}
          >
            Contact Us
          </Typography>
        </Box>
        <Typography
          component={Link}
          to="https://wa.me/message/OBRZ3EOKASHTD1?src=qr"
          sx={{
            color: "#797979",
            textDecoration: "none!important",
            fontFamily: "SF Pro Display",
            fontSize: " 22px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "34.628px",
            letterSpacing: "1.32px",
            textTransform: "lowercase",
            "@media (max-width:700px)": {
              fontSize: "14px",
              lineHeight: "20px",
            },
          }}
        >
          +971 521 538152
        </Typography>
      </Box> */}
    </Box>
  );
}
