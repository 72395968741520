export const ACTION_GET_DASHBOARD = "ACTION_GET_DASHBOARD";
export const ACTION_CHANGE_CURRENCY = "ACTION_CHANGE_CURRENCY";
export const ACTION_CHANGE_KYC_IDENTITY = "ACTION_CHANGE_KYC_IDENTITY";
export const ACTION_CHANGE_KYC_COMPANY = "ACTION_CHANGE_KYC_COMPANY";
export const ACTION_CHANGE_KYC_BANK = "ACTION_CHANGE_KYC_BANK";
const initialState = {
  totalPortfolioValue: {
    usd: 0,
    aed: 0,
  },
  volume: {
    usd: 0,
    aed: 0,
  },
  recentTransactions: null,
  showCurrency: "usd",
  cryptoTokens: null,
  balancesData: null,
  totalTransactions: 0,
  uniqueUserCount: 0,
  kycStatus: null,
};

export const DashboardReducer = (state = initialState, action) => {
  // const returnObj = { ...state }
  switch (action.type) {
    case ACTION_GET_DASHBOARD:
      let usdBal = 0;
      let aedBal = 0;
      const balances = action?.payload?.data?.balances;
      if (balances.length > 0) {
        balances?.map((item) => {
          usdBal = Number(item?.balance) * Number(item?.realTimePrice?.usd);
          return (aedBal =
            Number(item?.balance) * Number(item?.realTimePrice?.aed));
        });
      }
      return {
        ...state,
        balancesData: action?.payload?.data,
        totalPortfolioValue: {
          usd: usdBal,
          aed: aedBal,
        },
        recentTransactions: action?.payload?.data?.recentTransactions,
        volume: {
          usd: 0,
          aed: 0,
        },
        totalTransactions: action?.payload?.data?.transactionCount,
        uniqueUserCount: action?.payload?.data?.userCount?.uniqueuserscount,
        kycStatus: action?.payload?.data?.kycStatus,
      };
    case ACTION_CHANGE_CURRENCY:
      return {
        ...state,
        showCurrency: action?.payload,
      };

    case ACTION_CHANGE_KYC_IDENTITY:
      return {
        ...state,
        kycStatus: {
          ...state?.kycStatus,
          identity: action?.payload?.data?.identity,
        },
      };

    case ACTION_CHANGE_KYC_COMPANY:
      return {
        ...state,
        kycStatus: {
          ...state?.kycStatus,
          company: action?.payload?.data?.company,
        },
      };

    case ACTION_CHANGE_KYC_BANK:
      return {
        ...state,
        kycStatus: { ...state?.kycStatus, bank: action?.payload?.data?.bank },
      };

    default:
      return state;
  }
};
