import { Box, CircularProgress, Typography } from "@mui/material";
import { LogoSvg } from "./svg";
import React, { useState } from "react";
import { useTheme } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Tooltip } from "@mui/material";
import { Snackbar } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import MuiAlert from "@mui/material/Alert";
import { Link } from "react-router-dom";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function MultipleSelect(props) {
  const { data, setValues, values } = props;
  const theme = useTheme();

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    // setPersonName(
    //   // On autofill we get a stringified value.
    //   typeof value === 'string' ? value.split(',') : value,
    // )
    setValues((prev) => ({
      ...prev,
      country: typeof value === "string" ? value.split(",") : value,
    }));
  };

  return (
    <>
      <FormControl sx={props?.sx}>
        <InputLabel id="demo-multiple-name-label">Name</InputLabel>
        <Select
          labelId="demo-multiple-name-label"
          id="demo-multiple-name"
          multiple
          value={values || ""}
          onChange={handleChange}
          input={<OutlinedInput label="Name" />}
          MenuProps={MenuProps}
        >
          {data?.map((name) => (
            <MenuItem
              key={name}
              value={name}
              style={getStyles(name, values, theme)}
            >
              {name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
}

export const SelectComponent = (props) => {
  const [age, setAge] = useState(10);

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  return (
    <>
      <Box sx={{ minWidth: 250 }}>
        <FormControl fullWidth>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            onChange={handleChange}
            sx={{
              outline: "1px solid #FFFFFF",
              border: "1px solid #FFFFFF",
              color: "#ffffff",
            }}
            value={age}
            size="small"
          >
            <MenuItem value={10}>XXXX XXXX 48152</MenuItem>
            <MenuItem value={20}>XXXX XXXX 48152</MenuItem>
            <MenuItem value={30}>XXXX XXXX 48152</MenuItem>
          </Select>
        </FormControl>
      </Box>
    </>
  );
};

export const CopyToClipboardButton = ({ content, background, fontsize }) => {
  const [open, setOpen] = useState(false);
  const handleClick = () => {
    setOpen(true);
    navigator.clipboard.writeText(content || "");
  };

  return (
    <>
      <Tooltip title="Click to copy" arrow>
        <ContentCopyIcon
          onClick={handleClick}
          sx={{
            background: background || "#0002",
            p: "7px 5px",
            borderRadius: "5px",
            fontSize: fontsize || "30px !important",
            color: "#000",
            cursor: "pointer",
            // border: '1px solid #000'
          }}
        />
      </Tooltip>

      <Snackbar
        open={open}
        onClose={() => setOpen(false)}
        autoHideDuration={2000}
        message="Copied to clipboard"
      />
    </>
  );
};

export const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const CustomizedSnackbars = (props) => {
  const { opens, severity } = props;
  const [open, setOpen] = useState(opens || false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  return (
    <Stack spacing={2} sx={{ width: "100%" }}>
      <Button variant="outlined" onClick={handleClick}>
        Open success snackbar
      </Button>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity={severity || "error"}
          sx={{ width: "100%" }}
        >
          This is a success message!
        </Alert>
      </Snackbar>
      <Alert severity="error">This is an error message!</Alert>
      <Alert severity="warning">This is a warning message!</Alert>
      <Alert severity="info">This is an information message!</Alert>
      <Alert severity="success">This is a success message!</Alert>
    </Stack>
  );
};

export const LogoText = () => (
  <Box
    component={Link}
    to="/"
    sx={{
      display: "flex",
      gap: "10px",
      width: "100%",
      maxWidth: "508px",
      alignItems: "center",
      textDecoration: "none",
    }}
  >
    <LogoSvg />

    <Typography
      sx={{
        color: "#000",
        fontFamily: "Montserrat Alternates",
        fontSize: "27.61px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "normal",
      }}
    >
      Gafah
    </Typography>
  </Box>
);

export const AuthHeader = ({ color, text, mt, fs, fw }) => (
  <Typography
    sx={{
      mt: mt || "15px",
      color: color || "#000",
      fontFeatureSettings: `'ss06' on`,
      fontFamily: "SF Pro Display",
      fontSize: fs || "40px",
      fontStyle: "normal",
      fontWeight: fw || 500,
      lineHeight: "56px",
      letterSpacing: " -0.4px",
    }}
  >
    {text}
  </Typography>
);

export const AuthHeader2 = ({ text, mt }) => (
  <Typography
    sx={{
      mt: mt || "15px",
      color: "#263238",
      fontFeatureSettings: `'ss06' on`,
      fontFamily: "SF Pro Display",
      fontSize: "32px",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "125%",
      letterSpacing: " -0.32px",
    }}
  >
    {text}
  </Typography>
);

export const AuthHeader3 = ({ text, mt }) => (
  <Typography
    sx={{
      color: "#000",
      fontFeatureSettings: "ss06 o",
      fontFamily: "SF Pro Display",
      fontSize: "24px",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "150%" /* 36px */,
      letterSpacing: "-0.24px",
    }}
  >
    {text}
  </Typography>
);

export const AuthPRight = ({ text, mt, fs, lh }) => (
  <Typography
    sx={{
      mt: mt || "5px",
      color: "var(--neutral-500, #3C3C3C)",
      fontFeatureSettings: `'ss06' on`,
      fontFamily: "SF Pro Display",
      fontSize: fs || "18px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: lh || "27.9px",
    }}
  >
    {text}
  </Typography>
);
export const AuthP = ({ text, mt, mb, fs }) => (
  <Typography
    sx={{
      mt: mt || "5px",
      color: "var(--neutral-500, #717184)",
      fontFeatureSettings: `'ss06' on`,
      fontFamily: "SF Pro Display",
      fontSize: fs || "18px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "27.9px",
      mb: mb,
    }}
  >
    {text}
  </Typography>
);

export const Header5 = ({ text, mt, fs }) => (
  <Typography
    sx={{
      mt: mt || "15px",
      color: "#000",
      fontFeatureSettings: `'ss06' on`,
      fontFamily: "SF Pro Display",
      fontSize: fs || "20px",
      fontStyle: "normal",
      fontWeight: 500,
      letterSpacing: " -0.4px",
    }}
  >
    {text}
  </Typography>
);

export const Header6 = ({ text, mt, fs }) => (
  <Typography
    sx={{
      mt: mt || "15px",
      color: "var(--Neutral-900, #131316)",
      fontFeatureSettings: `'ss06' on`,
      fontFamily: "SF Pro Display",
      fontSize: fs || "14px",
      fontStyle: "normal",
      fontWeight: 400,
      letterSpacing: " -0.4px",
    }}
  >
    {text}
  </Typography>
);

export const StepTypo = ({ title }) => (
  <Typography
    sx={{
      fontSize: "24px",
      fontWeight: "bold",
      display: "flex",
      // background: 'red',
      p: "10px",
      color: "#495ECA",
    }}
  >
    {title}
  </Typography>
);

export const LoaderCircular = () => (
  <Box
    sx={{
      display: "flex",
      width: "100%",
      height: "100%",
      alignItems: "center",
      justifyContent: "center",
    }}
  >
    <CircularProgress />
  </Box>
);

export const UrlReject = ({ my, text }) => (
  <Box
    sx={{
      my: my,
    }}
  >
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <AuthHeader text="404" fs="48px" fw="900" color="red" mt="0px" />
      <AuthHeader text="Not Found" fs="40px" fw="500" color="red" />
    </Box>
    <AuthPRight text={text} />
  </Box>
);

export const Failed = ({ my, text, showComponent, backComponent }) => (
  <Box
    sx={{
      my: my,
    }}
  >
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      {showComponent}
      <AuthHeader text="Expired" fs="40px" fw="500" color="red" />
    </Box>
    <AuthPRight text={text} />
    {backComponent && (
      <Box
        sx={{
          display: "flex",
          width: "100%",
          justifyContent: "center",
          mt: "40px",
        }}
      >
        {backComponent}
      </Box>
    )}
  </Box>
);

export const DescriptionComponent = ({
  color,
  text,
  mt,
  fs,
  fw,
  ff,
  ta,
  mb,
  mw
}) => (
  <Typography
    sx={{
      mt: mt || "0px",
      color: color || "#959595",
      fontFeatureSettings: `'ss06' on`,
      fontFamily: ff || "Poppins",
      fontSize: fs || "23.707px",
      fontStyle: "normal",
      fontWeight: fw || 500,
      // lineHeight: lh || "36.746px",
      letterSpacing: " -0.4px",
      textAlign: ta || "left",
      mb: mb || "0px",
      maxWidth:mw|| "auto"
    }}
  >
    {text}
  </Typography>
);
