import * as React from "react";
import { styled, ThemeProvider, createTheme } from "@mui/system";
import { Box, Typography } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";

// const theme = createTheme();

const BorderLinearProgress = styled(LinearProgress)({
  height: 10,
  width: "100px",
  "& .MuiLinearProgress-bar": {
    backgroundColor:"#495ECA",
  },
});

export default function PercentageSlider({percent}) {
  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
      <Typography
        sx={{
          color: "var(--neutral-500, #717184)",
          fontFeatureSettings: `'ss06' on`,
          fontFamily: "SF Pro Display",
          fontSize: "16px",
          fontStyle: "normal",
          fontWeight: 400,
          lineHeight: "16.2px",
          letterSpacing: "0.06px",
        }}
      >
        {percent}%
      </Typography>
      <BorderLinearProgress variant="determinate" value={percent} />
    </Box>
  );
}
