import React from "react";
import { Box, Typography } from "@mui/material";

export default function ComponentTwo() {
  return (
    <Box
      sx={{
        p: "64px 30px 80px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Typography
        sx={{
          color: "#495ECA",
          fontFeatureSettings: `'ss06' on`,
          fontFamily: "SF Pro Display",
          fontSize: "80px",
          fontStyle: "normal",
          fontWeight: 500,
          lineHeight: "88px",
          letterSpacing: "-0.8px",
        }}
      >
        “
      </Typography>
      <Typography
        sx={{
          color: "var(--main-colors-black, #0E0637)",
          textAlign: "center",
          fontFeatureSettings: `'ss06' on`,
          fontFamily: "SF Pro Display",
          fontSize: "40px",
          fontStyle: "normal",
          fontWeight: 500,
          lineHeight: "56px",
          letterSpacing: "-0.4px",
          maxWidth: "736px",
          mb: "40px",
          "@media (max-width:600px)":{fontSize:"2.075rem!important", lineHeight:"50px"}
        }}
      >
        A stat that drives us: There’s a 40% New Customer increase from crypto payments being enabled.
      </Typography>
      {/* <Box
        sx={{
          display: "flex",
          alignItems: "flex-start",
          gap: "16px",
          mb: "40px",
        }}
      >
        <svg
          width="41"
          height="40"
          viewBox="0 0 41 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect x="0.5" width="40" height="40" rx="20" fill="#D4AFBD" />
        </svg>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "2px",
          }}
        >
          <Typography
            sx={{
              color: "#000",
              textAlign: "center",
              fontFeatureSettings: `'ss06' on`,
              fontFamily: "SF Pro Display",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "20.3px",
            }}
          >
            John Clayton
          </Typography>
          <Typography
            sx={{
              color: "var(--neutral-500, #717184)",
              textAlign: "center",
              fontFeatureSettings: `'ss06' on`,
              fontFamily: "SF Pro Display",
              fontSize: "12px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "16.3px",
              letterSpacing: "0.06px",
            }}
          >
            Investor
          </Typography>
        </Box>
      </Box> */}
      <Typography
        sx={{
          color: "#495ECA",
          fontFeatureSettings: `'ss06' on`,
          fontFamily: "SF Pro Display",
          fontSize: "80px",
          fontStyle: "normal",
          fontWeight: 500,
          lineHeight: "88px",
          letterSpacing: "-0.8px",
        }}
      >
        ”
      </Typography>
    </Box>
  );
}
