import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export default function SelectSmall() {
  const [age, setAge] = React.useState('');

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  return (
    <FormControl sx={{ mt: 1, width:"200px!important"}} size="small">
      <Select
        labelId="demo-select-small-label"
        id="demo-select-small"
        // value={age}
        // label="Language"
        defaultValue={10}
        onChange={handleChange}
        sx={{width:"100%"}}
      >
        <MenuItem sx={{fontWeight:500, fontFamily:"SF Pro Display"}}  value={10}>English</MenuItem>
        {/* <MenuItem  sx={{fontWeight:500, fontFamily:"SF Pro Display"}} value={20}>Spanish</MenuItem>
        <MenuItem sx={{fontWeight:500, fontFamily:"SF Pro Display"}}  value={30}>Chinese</MenuItem> */}
      </Select>
    </FormControl>
  );
}
