import React, { useEffect, useState } from "react";
import { Box, Typography, Grid } from "@mui/material";
import { DescriptionComponent } from "../../../components/Helpers/helperComponents";

export default function CSixGridComponent(props) {
  const { img, head, content, rightAlignment, svg } = props;
  const [alignment, setAlignment] = useState(false);
  useEffect(() => {
    // Function to update the flag based on the screen width
    const updateFlag = () => {
      setAlignment(window.innerWidth < 800)
    };

    // Initial call to set the flag based on the initial screen width
    updateFlag();

    // Event listener to update the flag when the window is resized
    window.addEventListener('resize', updateFlag);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', updateFlag);
    };
  }, []);
  return (
    <>
      {rightAlignment || alignment ? (
        <Grid
          container
          elevation={2}
          sx={{
            mt: "170px",
            width: "100%",
            "@media (max-width:800px)": { mt: "60px!important" },
          }}
        >
          {" "}
          <Grid
            item
            xs={12}
            md={6}
            lg={6}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {svg ||
              <Box
                component="img"
                src={img}
                sx={{ height: "auto", width: "450px", "@media (max-width:500px)": { width: "300px" }, }}
              />
            }
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            lg={6}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                maxWidth: "430px",
                "@media (max-width:800px)": { maxWidth: "auto", mt: "30px" },
              }}
            >
              <Typography
                sx={{
                  color: "#110C2C",
                  textAlign: "center",
                  fontFeatureSettings: " 'ss06' on",
                  fontFamily: "Poppins",
                  fontSize: "32px",
                  fontStyle: "normal",
                  fontWeight: "700",
                  lineHeight: "42.6px",
                  "@media (max-width:600px)": {
                    fontSize: "2rem",
                    lineHeight: "30px"
                  }
                }}
              >
                {head}
              </Typography>
              <DescriptionComponent
                text={content}
                fs="25px"
                fw="400"
                ta="center"
                mt="16px"
              />

            </Box>
          </Grid>
        </Grid>
      ) : (
        <Grid
          container
          elevation={2}
          sx={{
            mt: "170px",
            width: "100%",
            "@media (max-width:800px)": { mt: "70px" },
          }}
        >
          <Grid
            item
            xs={12}
            md={6}
            lg={6}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                maxWidth: "430px",
                "@media (max-width:800px)": { maxWidth: "auto", mt: "30px" },
              }}
            >
              <Typography
                sx={{
                  color: "#110C2C",
                  textAlign: "center",
                  fontFeatureSettings: " 'ss06' on",
                  fontFamily: "Poppins",
                  fontSize: "32px",
                  fontStyle: "normal",
                  fontWeight: "700",
                  lineHeight: "42.6px",
                  "@media (max-width:600px)": {
                    fontSize: "2rem",
                    lineHeight: "30px",
                  }
                }}
              >
                {head}
              </Typography>
              <DescriptionComponent
                text={content}
                fs="25px"
                fw="400"
                ta="center"
                mt="16px"
              />

            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            lg={6}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              component="img"
              src={img}
              sx={{ height: "auto", width: "450px", "@media (max-width:500px)": { width: "300px" }, }}
            />
          </Grid>
        </Grid>
      )}

    </>
  );
}
