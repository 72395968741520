export const ACTION_GET_TRANSACTIONS = "ACTION_GET_TRANSACTIONS"
export const ACTION_GET_ALLTOKENS = "ACTION_GET_ALLTOKENS"
export const ACTION_GET_ALL_PRICES = "ACTION_GET_ALL_PRICES"

const initialState = {
    transactions: [],
    alltokens: [],
    prices: [],
    tokens: [], 
    networks: [], 
    minimumWithDraw: null
}

export const TransactionReducer = (state = initialState, action) => {
    // const returnObj = { ...state }
    switch (action.type) {
        case ACTION_GET_TRANSACTIONS:
            return {
                ...state,
                transactions: action?.payload?.data,
            }
        case ACTION_GET_ALLTOKENS:
            return {
                ...state,
                alltokens: action?.payload?.data,
            }
        case ACTION_GET_ALL_PRICES:
            return {
                ...state,
                prices: action?.payload?.data,
            }

        default:
            return state
    }
}

