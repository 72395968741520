// eslint-disable-next-line import/no-anonymous-default-export
export default {
    registration: 'userapi/auth/merchant/register',
    login: 'userapi/auth/merchant/login',
    otpVerification: 'userapi/auth/merchant/verify-otp',
    resendOtp: 'userapi/auth/merchant/send-otp',
    updateProfile: 'userapi/auth/merchant/update-profile',
    refreshToken: 'userapi/auth/merchant/generate-access-token',

    // forget password
    inititateForgetPass: 'userapi/merchant/inititate-forgot-password',
    verifyForgetPassOtp: 'userapi/merchant/verify-forgot-password',
    resendForgetPassOtp: 'userapi/merchant/resend-forgot-password-otp',
    resetPassword: 'userapi/merchant/reset-password',


    // userapi-key
    genarateApiKey: 'userapi/merchant/generate-userapi-key',
    getApiKey: 'userapi/merchant/get-apikey-details',
    updateApiKeyCallback: 'userapi/merchant/update-userapi-key-callback',
    lockApiKey: 'userapi/merchant/lock-userapi-key',
    deleteApiKey: 'userapi/merchant/delete-apikey',

    // user 
    dashboard: 'userapi/merchant/dashboard',
    changePassword: 'userapi/merchant/change-password',
    initiateEmailChange: 'userapi/merchant/initiate-change-email',
    resendEmailOtp: 'userapi/merchant/resend-change-email-otp',
    changeEmail: 'userapi/merchant/verify-change-email',
    initiatePhoneChange: 'userapi/merchant/initiate-change-phone',
    resendPhoneChangeOTP: 'userapi/merchant/resend-change-phone-otp',
    changePhone: 'userapi/merchant/verify-change-phone',
    getMarchant: 'userapi/merchant/get',
    editMarchant: 'userapi/merchant/edit',

    contactUs: 'userapi/merchant/contact',

    alltokens: 'userapi/merchant/all-tokens',
    transactions: 'userapi/merchant/transactions',
    prices: 'userapi/merchant/prices',

    // kyc 
    identityVerification: 'userapi/merchant/identity-verification',
    companyVerification: 'userapi/merchant/company-verification',
    bankVerification: 'userapi/merchant/bank-verification',
    
    // POS
    invoiceDetails:'api/paymentgateway/getInvoiceDetails',
    intiateInvoicePayment:'api/paymentgateway/initiateInvoicePayment',
    getCurrencies:"api/paymentgateway/getCurrencies",
    getRealTimePrice:"api/paymentgateway/getRealTimePrice",
    cancelTransaction:"api/paymentgateway/rejectInvoicePayment",
    tokenList:"userapi/token-list",
    refund: "api/v1/insertRefundTransaction"
}

// https://drive.google.com/file/d/1ZyNE7qvQK-Cw2DS1GPK_jPrr1AX7GxOM/view?usp=sharing
// https://drive.google.com/file/d/1fc8wonLKkPjWR-JsAtLk2CJcJv0TnUcq/view?usp=sharing
// https://drive.google.com/file/d/1C9HAjJ1lifBbkX44-agvGNCMg7WH5Y25/view?usp=sharing
// https://drive.google.com/file/d/1C9HAjJ1lifBbkX44-agvGNCMg7WH5Y25/view?usp=sharing
// https://drive.google.com/file/d/1C9HAjJ1lifBbkX44-agvGNCMg7WH5Y25/view?usp=sharing

// https://res.cloudinary.com/dyr5pe2er/image/upload/v1701865389/CryptoLogo/eth_logo_qu0tde.png