export const ACTION_INVOICE_DETAILS = "ACTION_INVOICE_DETAILS";
export const ACTION_INITIATE_INVOICE_PAYMENT = "ACTION_INITIATE_INVOICE_PAYMENT";
export const ACTION_GET_LIST_OF_CURRENCIES = "ACTION_GET_LIST_OF_CURRENCIES";
export const ACTION_GET_REAL_TIME_PRICE = "ACTION_GET_REAL_TIME_PRICE";
export const ACTION_SET_SOCKET_DATA = "ACTION_SET_SOCKET_DATA";
const initialState = {
  amount: 0,
  currency: null,
  paymentid: null,
  redirectlink: null,
  listofcurrencies: [],
  realTimePrice: {},
  externalPublicKey: null,
  expireIn: null,
  socketPayment: null,
  selectedWallet: null,
  paymentDetails: null
};

export const POSReducer = (state = initialState, action) => {
  // const returnObj = { ...state }
  switch (action.type) {
    case ACTION_INVOICE_DETAILS:
      return {
        ...state,
        amount: action?.payload?.data?.amount,
        currency: action?.payload?.data?.currency,
        paymentid: action?.payload?.data?.id?.id,
        paymentDetails: null,
        socketPayment: null,

      };

    case ACTION_SET_SOCKET_DATA:
      return {
        ...state,
        socketPayment: action?.payload,
      };
    case ACTION_INITIATE_INVOICE_PAYMENT:
      return {
        ...state,
        selectedWallet: action?.reqPayload?.wallet,
        paymentDetails: action?.payload
      };
    case ACTION_GET_LIST_OF_CURRENCIES:
      return {
        ...state,
        listofcurrencies: action?.payload?.data?.tokens
      }
    case ACTION_GET_REAL_TIME_PRICE:
      return {
        ...state,
        realTimePrice: action?.payload?.data?.price
      }
    default:
      return state;
  }
};
