import React from 'react'
import { Navigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import DashboardLayout from '../Drawer'

const AccessGuard = ({ accKey, component }) => {
    const { accessKey } = useSelector(state => state.auth)
    return accKey === accessKey ?
        component :
        <Navigate to="/login/" />
}

export const AccessGuardWithLayout = ({ accKey, component }) => {
    const { accessKey } = useSelector(state => state.auth)
    return accKey === accessKey ?
        <DashboardLayout component={component} /> :
        <Navigate to="/login/" />
}

export default AccessGuard
