import React, { useState, useEffect } from 'react';
import { ResourceLoader } from '../Helpers/svg';
import { Box } from '@mui/material';

export const LoaderBox = () => <Box sx={{
    width: '100%',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
}}>
    <ResourceLoader />
</Box>

const LoaderComponent = ({ component }) => {
    const [showLoader, setShowLoader] = useState(false);

    useEffect(() => {
        setShowLoader(true);
        const onLoad = () => {
            const resources = window.performance.getEntriesByType('resource');
            const slowResources = resources.filter(resource => resource.duration > 2000); // 2000ms (2 seconds) threshold
            setShowLoader(slowResources?.length > 0 ? true : false);
        };
        setTimeout(() => {
            onLoad();
            setShowLoader(false)
        }, [1000])
    }, []);

    return showLoader ? <LoaderBox /> : component
};

export default LoaderComponent;

export const LoaderComponentMin = ({ component }) => {
    const [showLoader, setShowLoader] = useState(false);

    useEffect(() => {
        setShowLoader(true);
        const onLoad = () => {
            const resources = window.performance.getEntriesByType('resource');
            const slowResources = resources.filter(resource => resource.duration > 2000); // 2000ms (2 seconds) threshold
            setShowLoader(slowResources?.length > 0 ? true : false);
        };
        setTimeout(() => {
            onLoad();
            setShowLoader(false)
        }, [1750])
    }, []);

    return showLoader ? <LoaderBox /> : component
};