import React from "react";
import { Box, Typography } from "@mui/material";
import CSevenGrid from "./CSevenGrid";

export default function ComponentSeven() {
  return (
    <Box
      sx={{
        background: " linear-gradient(180deg, #FFF 0%, #495ECA 100%)",
        padding: "100px 160px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "64px",
        '@media (max-width:800px)':{
          px:"40px"
        }
      }}
    >
      <Box>
        <Typography
          sx={{
            color: "var(--main-colors-primary, #5235E8)",
            textAlign: "center",
            fontFeatureSettings: " 'ss06' on",
            fontFamily: "SF Pro Display",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "500",
            lineHeight: "19.6px",
            textTransform: "uppercase",
            letterSpacing: "0.56px",
          }}
        >
          Testimonials
        </Typography>
        <Typography
          sx={{
            color: "var(--main-colors-black, #0E0637)",
            textAlign: "center",
            fontFeatureSettings: " 'ss06' on",
            fontFamily: "Poppins",
            fontSize: "40px",
            fontStyle: "normal",
            fontWeight: "600",
            lineHeight: "56px",
            letterSpacing: "-0.4px",
            mt: "16px",
          }}
        >
          Building Trust Among 100K+ Pioneers
        </Typography>
        <Typography
          sx={{
            color: "var(--neutral-500, #717184)",
            textAlign: "center",
            fontFeatureSettings: " 'ss06' on",
            fontFamily: "SF Pro Display",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "25.6px",
            letterSpacing: "0.08px",
            mt: "16px",
          }}
        >
          Hear are some testimonials and why should you use Gafah
        </Typography>
      </Box>
      <CSevenGrid />
    </Box>
  );
}
