const data = process.env
console.log("config data>>", data)
const config = {
  // API_URL: "http://localhost:8080/",
  API_URL: "https://api.gafah.com/",
  // API_URL: "http://135.181.92.246/",
  per_page: 10,
  max_file_upload: 6,
  max_video_upload_size: 200 * 1024 * 1024,
  env: process.env.NODE_ENV === "production" ? "prod" : "local",
  APP_URL: "http://localhost:3000/",
}

export default config


 