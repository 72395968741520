import io from 'socket.io-client';
import config from '../config/config';

const customOrigin = "https://gafah-stage.netlify.app";
// const socket = io("http://135.181.92.246", {
//   // path:'/transaction/socket.io',
//     transports: ["websocket"],
//     extraHeaders: {
//     Origin: customOrigin,
//     },
// });
// io.sails.environment = 'development';
const socket = io(config.API_URL, {
  // path: '/transaction/socket.io',
  transports: ["websocket"],
  extraHeaders: {
    Origin: customOrigin,
  },
  environment: 'development'
});
const SocketService = {
  connect: () => {
    socket.connect();

    socket.on('connect', () => {
      console.log('Socket connected', socket.id);
    });

    socket.on('disconnect', () => {
      console.log('Socket disconnected', socket.id);
    });

    // Add more event listeners and handlers as needed
  },

  emitEvent: (eventName, data) => {
    console.log("emitEvent", eventName, data);
    socket.emit(eventName, data);
  },

  subscribeToEvent: (eventName, callback) => {
    socket.on(eventName, (data) => {
      callback(data);
    });
  },

  disconnect: () => {
    socket.disconnect();
  },
};

export default SocketService;
