import React from "react";
import { Box, Typography, Grid } from "@mui/material";
import { DescriptionComponent } from "../../../components/Helpers/helperComponents";

export default function CFiveCardComponent(props) {
  const { img, head, content, svg } = props;
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        background: "var(--main-colors-white, #FFF)",
        boxShadow: " 0px 12px 80px 0px rgba(82, 53, 232, 0.10)",
        border: "2px solid var(--primary-shades-100, #EFEDFD)",
        borderRadius: "24px",
        py: "20px",
        overflow: 'hidden',
        maxWidth: "524px",
        "@media (max-width:800px)": { mt: "20px", mb: "20px" },
      }}
    >
      {svg ? <Box sx={{
        mt: "-30px",
        transform: {
          sm: "scale(1)",
          xs: "scale(0.8)"
        },
        ml: {
          sm: '0px',
          xs: '20px'
        }
      }
      } >
        {svg}
      </Box> :
        <Box
          component="img"
          src={img}
          sx={{
            height: "221px",
            width: "auto",
            px: "30px",
            "@media (max-width:600px)": { maxWidth: "200px", height: "auto" },
          }}
        />
      }
      <Box sx={{ padding: "32px 48px 32px", "@media (max-width:600px)": { padding: "16px 24px 16px" }, }}>
        <Typography
          sx={{
            color: "#0E0637",
            textAlign: "center",
            fontFeatureSettings: " 'ss06' on",
            fontFamily: "SF Pro Display",
            fontSize: "24px",
            fontStyle: "normal",
            fontWeight: "600",
            lineHeight: "36px",
            textTransform: "none",
            mt: "34px",
            "@media (max-width:600px)": {
              fontSize: "20px",
              lineHeight: "26px",
            },
          }}
        >
          {head}
        </Typography>
        <DescriptionComponent
          text={content}
          mt="16px"
          fs="20px"
          ta="center"
          fw="400"
        />
       
      </Box>
    </Box >
  );
}
