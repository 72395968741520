import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { Box, CircularProgress, MenuItem, Typography } from "@mui/material";
import { CloseIcon } from "../Helpers/svg";

export default function AlertDialog({
  typoTitle,
  btnTitle,
  execute,
  title,
  content,
  controlled,
  controlModal,
  okayComponent,
  executeLoading,
}) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  React.useEffect(() => {
    controlled && setOpen(controlModal);
  }, [controlled, controlModal]);
  return (
    <React.Fragment>
      {controlled ? (
        <></>
      ) : typoTitle ? (
        <MenuItem
          key={"logout"}
          sx={{
            cursor: "pointer",
          }}
          onClick={handleClickOpen}
        >
          {typoTitle}
        </MenuItem>
      ) : (
        <Button
          variant="outlined"
          color="error"
          sx={{
            fontFeatureSettings: "ss06 on",
            fontFamily: "SF Pro Display",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: 500,
            letterSpacing: "0.08px",
            textTransform: "capitalize",
            minWidth: "max-content",
          }}
          onClick={handleClickOpen}
        >
          {btnTitle}
        </Button>
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent
          sx={{
            position: "relative",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            pt: "40px",
            width: {
              sm: "400px",
            },
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: "5px",
              right: "5px",
              display: "flex",
              width: "24px",
              height: "24px",
              padding: "4px",
              justifyContent: "center",
              alignItems: "center",
              gap: "10px",
              flexShrink: 0,
              borderRadius: "29px",
              background: "var(--grey-medium, #E5E5E5)",
              cursor: "pointer",
            }}
            onClick={handleClose}
          >
            <CloseIcon />
          </Box>
          <Typography
            id="alert-dialog-description"
            sx={{
              color: "var(--secondary-dark-color, #54595E)",
              fontFamily: "SF Pro Display",
              fontSize: "20px",
              fontStyle: "normal",
              fontWeight: 700,
              lineHeight: "normal",
            }}
          >
            {title}
          </Typography>
          <Typography
            sx={{
              color: "rgba(84, 89, 94, 0.60)",
              textAlign: "center",
              fontFamily: "SF Pro Display",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "22px",
              mt: "10px",
            }}
          >
            {content}
          </Typography>
        </DialogContent>
        <DialogActions sx={{ p: "20px" }}>
          {okayComponent ? (
            <Button
              sx={{
                display: "flex",
                height: "44px",
                justifyContent: "center",
                alignItems: "center",
                gap: "10px",
                flex: "1 0 0",
                borderRadius: "8px",
                border: "1px solid var(--dark-medium, #4F4F4F)",
                background: "var(--White, #FFF)",
                fontSize: "12px",
                color: "#000",
                textTransform: "capitalize",
              }}
              onClick={execute}
            >
              Okay
            </Button>
          ) : (
            <>
              <Button
                sx={{
                  display: "flex",
                  height: "44px",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "10px",
                  flex: "1 0 0",
                  borderRadius: "8px",
                  border: "1px solid var(--dark-medium, #4F4F4F)",
                  background: "var(--White, #FFF)",
                  fontSize: "12px",
                  color: "#000",
                  textTransform: "capitalize",
                }}
                onClick={handleClose}
              >
                No, cancel
              </Button>
              {executeLoading ? (
                <Button
                  sx={{
                    display: "flex",
                    height: "44px",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "10px",
                    flex: "1 0 0",
                    borderRadius: "8px",
                    background: "#495ECA",
                    color: "#F5F5F5",
                    fontSize: "12px",
                    textTransform: "capitalize",
                    "&:hover": {
                      background: "var(--main-colors-primary, #5235E8)",
                      transition: "0.4s",
                    },
                  }}
                  autoFocus
                >
                  <CircularProgress size="1.7rem" />
                </Button>
              ) : (
                <Button
                  sx={{
                    display: "flex",
                    height: "44px",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "10px",
                    flex: "1 0 0",
                    borderRadius: "8px",
                    background: "#495ECA",
                    color: "#F5F5F5",
                    fontSize: "12px",
                    textTransform: "capitalize",
                    "&:hover": {
                      background: "var(--main-colors-primary, #5235E8)",
                      transition: "0.4s",
                    },
                  }}
                  onClick={execute}
                  autoFocus
                >
                  Yes, Confirm
                </Button>
              )}
            </>
          )}
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export function DeleteDialog(props) {
  const { typoTitle, execute, title, content, close, opens } =
    props;
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    if (props?.close) close();
  };

  React.useEffect(() => {
    setOpen(opens);
  }, [opens]);
  return (
    <React.Fragment>
      <MenuItem
        key={"logout"}
        sx={{
          cursor: "pointer",
        }}
        onClick={handleClickOpen}
      >
        {typoTitle}
      </MenuItem>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent
          sx={{
            position: "relative",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            pt: "40px",
            width: {
              sm: "400px",
            },
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: "5px",
              right: "5px",
              display: "flex",
              width: "24px",
              height: "24px",
              padding: "4px",
              justifyContent: "center",
              alignItems: "center",
              gap: "10px",
              flexShrink: 0,
              borderRadius: "29px",
              background: "var(--grey-medium, #E5E5E5)",
              cursor: "pointer",
            }}
            onClick={handleClose}
          >
            <CloseIcon />
          </Box>
          <Typography
            id="alert-dialog-description"
            sx={{
              color: "var(--secondary-dark-color, #54595E)",
              fontFamily: "SF Pro Display",
              fontSize: "20px",
              fontStyle: "normal",
              fontWeight: 700,
              lineHeight: "normal",
            }}
          >
            {title}
          </Typography>
          <Typography
            sx={{
              color: "rgba(84, 89, 94, 0.60)",
              textAlign: "center",
              fontFamily: "SF Pro Display",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "22px",
              mt: "10px",
            }}
          >
            {content}
          </Typography>
        </DialogContent>
        <DialogActions sx={{ p: "20px" }}>
          <Button
            sx={{
              display: "flex",
              height: "44px",
              justifyContent: "center",
              alignItems: "center",
              gap: "10px",
              flex: "1 0 0",
              borderRadius: "8px",
              border: "1px solid var(--dark-medium, #4F4F4F)",
              background: "var(--White, #FFF)",
              fontSize: "12px",
              color: "#000",
              textTransform: "capitalize",
            }}
            onClick={handleClose}
          >
            No, cancel
          </Button>
          <Button
            sx={{
              display: "flex",
              height: "44px",
              justifyContent: "center",
              alignItems: "center",
              gap: "10px",
              flex: "1 0 0",
              borderRadius: "8px",
              background: "#495ECA",
              color: "#F5F5F5",
              fontSize: "12px",
              textTransform: "capitalize",
              "&:hover": {
                background: "var(--main-colors-primary, #5235E8)",
                transition: "0.4s",
              },
            }}
            onClick={execute}
            autoFocus
          >
            Yes, Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export function CommonDialog({
  typoTitle,
  btnTitle,
  execute,
  title,
  content,
  controlled,
  controlModal,
  okayComponent,
  closeModal,
  body,
}) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    if (controlled) closeModal();
    setOpen(false);
  };
  React.useEffect(() => {
    console.log(controlled, controlModal);
    controlled && setOpen(controlModal);
  }, [controlled, controlModal]);
  return (
    <React.Fragment>
      {controlled ? (
        <></>
      ) : typoTitle ? (
        <MenuItem
          key={"logout"}
          sx={{
            cursor: "pointer",
          }}
          onClick={handleClickOpen}
        >
          {typoTitle}
        </MenuItem>
      ) : (
        <Button
          variant="outlined"
          color="error"
          sx={{
            fontFeatureSettings: "ss06 on",
            fontFamily: "SF Pro Display",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: 500,
            letterSpacing: "0.08px",
            textTransform: "capitalize",
            minWidth: "max-content",
          }}
          onClick={handleClickOpen}
        >
          {btnTitle}
        </Button>
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
      >
        <DialogContent>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <Typography
              id="alert-dialog-description"
              sx={{
                fontFamily: "SF Pro Display",
                fontSize: "18px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "normal",
                color: "var(--Neutral-900, #131316)",
                fontFeatureSettings: "ss06 on",
              }}
            >
              {title}
            </Typography>
            <Box
              sx={{
                display: "flex",
                width: "24px",
                height: "24px",
                padding: "4px",
                justifyContent: "center",
                alignItems: "center",
                gap: "10px",
                flexShrink: 0,
                borderRadius: "29px",
                background: "var(--grey-medium, #E5E5E5)",
                cursor: "pointer",
              }}
              onClick={handleClose}
            >
              <CloseIcon />
            </Box>
          </Box>
          {body}
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
