import { Box, Typography } from "@mui/material";
import React from "react";

export default function ComponentFour() {
  return (
    <Box
      sx={{
        p: "64px 160px",
        display: "flex",
        justifyContent: "space-between",
        // alignItems: "center",
        "@media (max-width:1200px)": {
          p: "40px 30px!important",
          flexDirection: "column",
          gap: "40px",
          alignItems: "center",
        },
      }}
    >
      <Typography
        sx={{
          color: "var(--main-colors-black, #0E0637)",
          textAlign: "left",
          fontFeatureSettings: `'ss06' on`,
          fontFamily: "SF Pro Display",
          fontSize: "40px",
          fontStyle: "normal",
          fontWeight: 500,
          lineHeight: "56px",
          letterSpacing: "-0.4px",
          maxWidth: "448px",
          "@media (max-width:1200px)": {
            fontSize: "2.075rem!important",
            lineHeight: "50px",
            maxWidth: "auto!important",
            textAlign: "center",
          },
        }}
      >
        A vision of financial inclusion
      </Typography>
      <Typography
        sx={{
          color: " var(--neutral-500, #717184)",
          textAlign: "justify",
          fontFeatureSettings: `'ss06' on`,
          fontFamily: "Poppins",
          fontSize: "16px",
          fontStyle: "normal",
          fontWeight: 400,
          lineHeight: "25.6px",
          letterSpacing: "0.08px",
          maxWidth: "544px",
          "@media (max-width:600px)": {
            maxWidth: "auto!important",
            textAlign: "justify",
          },
        }}
      >
        Since our inception, financial inclusion has always been our guiding
        vision. In a world where 420 million people own cryptocurrency, we
        wanted to democratize finance so that anyone can seamlessly & securely
        use any of the digital assets they own. Supporting this, our mission is
        to pioneer a financial ecosystem where people can have all the financial
        tools available to them, in the way they want to use it. By offering a
        dual of centralized (custodial) and decentralized payment methods, we
        give the flexibility to merchants & users to pay and get paid the way
        they want to be.
      </Typography>
    </Box>
  );
}
