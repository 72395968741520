export const ACTION_GET_APIKEY = "ACTION_GET_APIKEY"

const initialState = {
  ecommerce: [],
  pos: [],
  
}

export const ApiKeyReducer = (state = initialState, action) => {
  // const returnObj = { ...state }
  switch (action.type) {
    case ACTION_GET_APIKEY:
      return {
        ...state,
        ecommerce: action?.payload?.data?.ecommerce,
        pos: action?.payload?.data?.pos,
      }

    default:
      return state
  }
}

