import React from "react";
import { Box } from "@mui/material";
import Footer from "./Footer";
import RevenueComponentEight from "./RevenueComponentEight";

export default function ComponentEight() {
  return (
    <Box
      sx={{
        background: "#495ECA",
        padding: "100px 160px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "64px",
        flexShrink: 0,
        '@media (max-width:800px)':{px:"40px!important"}
      }}
    >
      <RevenueComponentEight />
      <Footer />
    </Box>
  );
}
