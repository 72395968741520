import React, {useState, useEffect} from "react";
import { Box, Typography } from "@mui/material";
import Img from "../../../assets/img/home/ComponentThree/1.png";
import { DescriptionComponent } from "../../../components/Helpers/helperComponents";
export default function ComponentThree({ mt }) {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const isDesktop = windowWidth <= 1500;
  return (
    <Box
      sx={{
        mt: mt || "0px",
      }}
    >
      <Box
        className="HomeComponentThree"
        sx={{
          mt: "48px",
          mx: "20px",
          borderRadius: "30px!important",
          p: "70px 160px 0px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          // background:"linear-gradient(180deg, #000, #495ECA, #000)",
          "@media (max-width:1000px)": {
            px: "40px",
          },
        }}
      >
        <Typography
          sx={{
            color: "#495ECA",
            textAlign: "center",
            fontFeatureSettings: " 'ss06' on",
            fontFamily: "SF Pro Display",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "500",
            lineHeight: "19.6px",
            textTransform: "uppercase",
          }}
        >
          Features
        </Typography>
        <Typography
          sx={{
            color: " #fff",
            textAlign: "center",
            fontFeatureSettings: " 'ss06' on",
            fontFamily: "Poppins",
            fontSize: "40px",
            fontStyle: "normal",
            fontWeight: "600",
            lineHeight: "56px",
            letterSpacing: "-0.4px",
            mt: "16px",
            "@media (max-width:800px)": {
              fontSize: "2.075rem",
              lineHeight: "50px",
            },
          }}
        >
          Transforming daily <span style={{ color: "#495ECA" }}>payments</span>{" "}<br/>
          in the UAE
        </Typography>

        <DescriptionComponent
          text={
            <>
              In a time where 420 million people own some form of
              cryptocurrency, we believe our daily lives should reflect this
              growing reality. {!isDesktop && <br/>}Our UAE-based Merchant Crypto solutions allows
              businesses to securely accept crypto in seconds so you get the
              reputation {!isDesktop && <br/>}for being a forward-moving company, while expanding
              your revenue means.
            </>
          }
          ta="center"
          mt="16px"
          fs="18px"
          color="#fff"
        />

        <Box
          component="img"
          src={Img}
          sx={{
            mt: "64px",
            maxWidth: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        />
      </Box>
    </Box>
  );
}
