import api from "../../config/api";
import Network from "../../config/network";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


export const PostHandler = (req) => async (dispatch) => {
  try {
    const res = await Network.post(api[req?.url], req?.payload)
    if (req?.action) {
      dispatch({
        type: req?.action,
        payload: res?.data?.data,
        success: res?.data?.success,
        reqPayload: req?.reqPayload || req?.payload,
      });
    }
    toast.success(res?.data?.data?.message);
    return res;
  } catch (error) {
    console.error("Error post reqitems:", error);
    toast.error(error?.response?.data?.message);
    return error?.response;
  }
};

export const DeleteHandler = (req) => async (dispatch) => {
  try {
    const res = await Network.delete(api[req?.url], req?.payload)
    if (req?.action) {
      dispatch({
        type: req?.action,
        payload: res?.data,
        reqPayload: req?.payload,
      });
    }
    toast.success(res?.data?.message);
    return res;
  } catch (error) {
    console.error("Error post reqitems:", error);
    toast.error(error?.response?.data?.message);
    return error?.response;
  }
};

export const uploadHandler = (req) => async (dispatch) => {
  try {
    const res = await Network.post(api[req?.url], req?.payload, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    if (req?.action) {
      dispatch({
        type: req?.action,
        payload: res?.data,
        reqPayload: req?.payload,
      });
    }
    toast.success(res?.data?.message);
    return res;
  } catch (error) {
    console.error("Error post reqitems:", error);
    toast.error(error?.response?.data?.message);
    return error?.response;
  }
};

export const GetHandler = (req) => async (dispatch) => {
  try {
    const res = await Network.get(api[req?.url], req?.params);
    console.log("inside gethandler",res)
    if (req?.action) {
      dispatch({
        type: req?.action,
        state: req?.state,
        payload: res?.data,
        page: req?.params?.page,
      });
    }
    toast.success(res.data.message);
    return res;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    // console.error("Error post reqitems:", error);
    return error?.response?.data;
  }
};

export const SocialLoginHandler = (req) => async (dispatch) => {
  try {
    const res = await Network.socialLogin(api[req?.url]);
    if (res?.data?.success) {
      dispatch({
        type: res?.data?.detailsExistance
          ? "ACTION_LOGIN"
          : "ACTION_SOCIAL_SIGNUP",
        payload: res?.data,
        reqPayload: req?.payload,
      });
    }
    toast.success(res.data.message);
    return res;
  } catch (error) {
    toast.error(error.response.data.message);
    console.error("Error post reqitems:", error);
    return error?.response;
  }
};
